import * as React from 'react'
import { connect } from 'react-redux'

import { State as RootState } from '../../types'

import { Typography } from '@material-ui/core'
import { getVisibleDeviceIds } from '../../store/System/selectors'
import DeviceInfo from '../Device'

type Props = {
  deviceIds: string[]
  startTime: number
  endTime: number
}

const HistoryForm = (props: Props) => {
  return (
    <div>
      <h1>時間選択</h1>
      <form />
    </div>
  )
}

const ms = (state: RootState) => ({
  deviceIds: getVisibleDeviceIds(state),
  startTime: +new Date() - 3 * 60 * 1000,
  endTime: +new Date(),
})

const conn = connect(
  ms,
  {},
)

export default conn(HistoryForm)
