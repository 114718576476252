import _ from 'lodash'
import { State } from '../../types'

export const getTimestampStr = (state: State) => state.System.timestampStr
export const getTimestamp = (state: State) => state.System.timestamp
export const getLoadingData = (state: State) => state.System.loadingData

export const getDeviceVisibleExists = (state: State, id: string) =>
  getVisibleAllDeviceIds(state).includes(id)
export const getDeviceVisibles = (state: State) => state.System.deviceVisibles
export const getVisibleAllDeviceIds = (state: State) =>
  _.keys(state.System.deviceVisibles)
export const getVisibleDeviceIds = (state: State) => {
  const deviceVisibles = getDeviceVisibles(state)
  return _.keys(_.pickBy(deviceVisibles, Boolean))
}
