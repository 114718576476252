import * as React from 'react'
import { connect } from 'react-redux'

import moment, { Moment } from 'moment'
import {
  Area,
  AreaChart,
  AxisDomain,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import * as selectors from '../../store/DeviceLogsByDid/selectors'
import { DeviceStateLog, State as RootState } from '../../types'

type Props = {
  logs: DeviceStateLog[]
  domain?: [AxisDomain, AxisDomain]
}

const startOf5min = (m: Moment): Moment => {
  const minute = m.minute()
  return m.set({ minute: minute - (minute % 5), second: 0 })
}

const makeJustTimeTicks = (logs: DeviceStateLog[]): number[] => {
  if (logs.length === 0) {
    return []
  }
  const m = startOf5min(moment(logs[0].timestamp))
  const last = moment(logs[logs.length - 1].timestamp)
  const ticks = [] as number[]
  while (m.isBefore(last)) {
    ticks.push(m.unix() * 1000)
    m.add(5, 'minute')
  }
  return ticks
}

const DeviceStateLogs: React.SFC<Props> = props => {
  const { logs } = props
  if (logs.length === 0) {
    return <span>ログがありません。</span>
  }
  const ticks = makeJustTimeTicks(logs)
  return (
    <ResponsiveContainer aspect={4.618} width="100%">
      <AreaChart data={props.logs}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          ticks={ticks}
          type={'number'}
          domain={props.domain}
          dataKey="timestamp"
          tickFormatter={v => moment(v).format('HH:mm:ss')}
        />
        <YAxis
          ticks={[-1, 0, 1]}
          tickFormatter={v => ['着床', 'その他', '離床'][v + 1]}
          domain={[-1.1, 1.1]}
        />
        <Tooltip labelFormatter={v => moment(v).format('HH:mm:ss')} />
        <defs>
          <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset={'30%'} stopColor="orange" stopOpacity={1} />
            <stop offset={'70%'} stopColor="blue" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey={v => landingPatch(v.landing)}
          stroke="#000"
          fill="url(#splitColor)"
          isAnimationActive={false}
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

const landingPatch = (landing: 0 | 1 | 2): 0 | 1 | -1 => {
  // 0: 離, 1: 着, 2: 他 => 0: 他, 1: 離, -1: 着
  if (landing === 0) {
    return 1
  }
  if (landing === 1) {
    return -1
  }
  return 0
}

type OProps = {
  deviceId: string
}

const ms = (state: RootState, op: OProps) => ({
  logs: selectors.getDeviceStateLogs(state, op.deviceId),
})

const conn = connect(
  ms,
  {},
)

export default conn(DeviceStateLogs)
