import camelcaseKeys from 'camelcase-keys'
import firebase from 'firebase/app'
import 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import _ from 'lodash'
import moment from 'moment'
import { initializeFirebase } from '../../services/firebase'
import * as authActions from '../../store/Auth/actions'
import { getDeviceIds } from '../../store/Auth/selectors'
import { receiveDevice, updateDevice } from '../../store/DeviceById/actions'

import {
  saveDeviceLogs,
  saveDeviceStateLogs,
} from '../../store/DeviceLogsByDid/operations'
import {
  DeviceLogRaw,
  DeviceRaw,
  DeviceStateLogRaw,
  ThunkAction,
  User,
} from '../../types'
import { registerDeviceId } from '../System/operations'

const camelize = v => camelcaseKeys(v, { deep: true })

initializeFirebase()
const fdb = firebase.database()

export function login(): ThunkAction {
  return dispatch => {
    const provider = new firebase.auth.GoogleAuthProvider()
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async res => {
        const user = await omitUser(res.user)
        const userRef = fdb.ref(`user/${user.id}`)
        userRef.set(user)
        await dispatch(authActions.login(user))
      })
  }
}

export function passwordLogin(email: string, password: string) {
  return async dispatch => {
    console.log('passwordLogin')
    const res = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
    console.log(res)
    const user = await omitUser(res.user)
    const userRef = fdb.ref(`user/${user.id}`)
    userRef.set(user)
    dispatch(authActions.login(user))
  }
}

export function logout(): ThunkAction {
  return async dispatch => {
    await firebase
      .auth()
      .signOut()
      .catch(console.error)
    dispatch(authActions.logout())
  }
}

async function omitUser(user: any): Promise<User> {
  const userOldSnap = await fdb.ref(`user/${user.uid}`).once('value')
  const userOld = userOldSnap.exists() ? userOldSnap.val() : user

  // 基本DBにあるユーザ情報優先
  const displayName = userOld.displayName || user.displayName || 'no name'
  const photoURL = user.photoURL || userOld.photoURL || ''
  const deviceIds = userOld.deviceIds || {}
  return {
    id: user.uid,
    displayName,
    photoURL,
    deviceIds,
  }
}

export function refInit(): ThunkAction {
  return async dispatch => {
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const userFull = (await fdb.ref(`user/${user.uid}`).once('value')).val()
        if (userFull) {
          dispatch(authActions.login(userFull))
        } else {
          dispatch(authActions.loginFailed())
        }
      } else {
        dispatch(authActions.loginFailed())
      }
    })
  }
}

export function requestData(): ThunkAction {
  return async (dispatch, getState) => {
    const deviceIds = getDeviceIds(getState())
    if (!deviceIds) {
      return
    }
    console.log(deviceIds)
    const time30MinAgo =
      moment()
        .add(-30, 'minutes')
        .unix() * 1000
    await Promise.all(
      deviceIds.map(async deviceId => {
        const deviceSnap = await fdb.ref(`device/${deviceId}/`).once('value')
        const device = camelize(deviceSnap.val())
        const logCountsSnap = await fdb
          .ref(`device-log-count/${deviceId}/day/`)
          .once('value')
        await dispatch(registerDeviceId(deviceId))
        await dispatch(
          receiveDevice({
            ...device,
            id: deviceId,
            timestampStr: moment(device.timestamp).format(
              'YYYY/MM/DD HH:mm:ss',
            ),
            // 0: 離床 1: 着床 2: その他
            landingStr: ['離床中', '着床中', 'その他'][device.landing],
            logCounts: logCountsSnap.val(),
          }),
        )
      }),
    )
    deviceIds.forEach(deviceId => {
      fdb.ref(`device/${deviceId}/`).on('value', snap => {
        if (!snap) {
          return
        }
        const device: DeviceRaw = camelize(snap.val())
        console.log(device)
        dispatch(
          updateDevice({
            ...device,
            id: deviceId,
            timestampStr: moment(device.timestamp).format(
              'YYYY/MM/DD HH:mm:ss',
            ),
            // 0: 離床 1: 着床 2: その他
            landingStr: ['離床中', '着床中', 'その他'][device.landing],
          }),
        )
      })

      const saveDeviceStateLogsSnap = snap => {
        if (!snap) {
          return
        }
        const logRaw: DeviceStateLogRaw = camelize(snap.val())
        dispatch(saveDeviceStateLogs(deviceId, logRaw))
      }

      fdb
        .ref(`device-state-log/${deviceId}/`)
        .orderByChild('timestamp')
        .startAt(time30MinAgo)
        .on('child_added', saveDeviceStateLogsSnap)

      const saveDeviceLogsSnap = snap => {
        if (!snap) {
          return
        }
        const logRaw: DeviceLogRaw = camelize(snap.val())
        dispatch(saveDeviceLogs(deviceId, logRaw))
      }
      fdb
        .ref(`device-log/${deviceId}/`)
        .orderByChild('timestamp')
        .startAt(time30MinAgo)
        .on('child_added', saveDeviceLogsSnap)
    })
  }
}

const dayEndsUnix = (day: string) => {
  const m = moment(day, 'YYYY-MM-DD')
  const dayStart = m.startOf('day').unix() * 1000
  const dayEnd = m.endOf('day').unix() * 1000
  return { dayStart, dayEnd }
}

export async function loadLogs(
  deviceId: string,
  day: string,
): Promise<DeviceLogRaw[]> {
  const { dayStart, dayEnd } = dayEndsUnix(day)
  const snap = await fdb
    .ref(`device-log/${deviceId}/`)
    .orderByChild('timestamp')
    .startAt(dayStart)
    .endAt(dayEnd)
    .once('value')
  if (!snap) {
    return []
  }

  const logs: DeviceLogRaw[] = _.values(snap.val())
  return logs
}

export async function loadStateLogs(
  deviceId: string,
  day: string,
): Promise<DeviceLogRaw[]> {
  const { dayStart, dayEnd } = dayEndsUnix(day)
  const snap = await fdb
    .ref(`device-state-log/${deviceId}/`)
    .orderByChild('timestamp')
    .startAt(dayStart)
    .endAt(dayEnd)
    .once('value')
  if (!snap) {
    return []
  }

  const logs: DeviceLogRaw[] = _.values(snap.val())
  return logs
}
