import * as React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import { State } from '../../types'

import { Checkbox, FormControlLabel } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { Card } from '@material-ui/core'
import { getDeviceVisibles } from '../../store/System/selectors'
import { setInvisibleDeviceId, setVisibleDeviceId } from '../System/operations'

type Props = {
  deviceVisibles: { [id: string]: boolean }
  onCheckOn: (id: string) => void
  onCheckOff: (id: string) => void
}

const DeviceVisibleButtons: React.SFC<Props> = props => {
  const { deviceVisibles } = props
  return (
    <Card>
      <Typography>表示/非表示</Typography>
      {_.map(deviceVisibles, (visible, did) => (
        <FormControlLabel
          key={did}
          control={
            <Checkbox
              checked={visible}
              onChange={v => {
                if (v.currentTarget.checked) {
                  props.onCheckOn(did)
                } else {
                  props.onCheckOff(did)
                }
              }}
              value={did}
            />
          }
          label={did}
        />
      ))}
    </Card>
  )
}

type OProps = {}
type SProps = {
  deviceVisibles: { [id: string]: boolean }
}
type DProps = {
  onCheckOn: (id: string) => void
  onCheckOff: (id: string) => void
}
export default connect<SProps, DProps, OProps, State>(
  (state, op) => ({
    deviceVisibles: getDeviceVisibles(state),
  }),
  {
    onCheckOn: setVisibleDeviceId,
    onCheckOff: setInvisibleDeviceId,
  },
)(DeviceVisibleButtons)
