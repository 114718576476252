import { TextField } from '@material-ui/core'
import { Button } from '@material-ui/core'
import * as React from 'react'
import { FieldError, LoginField } from '../../../types'

export type Props = {
  field: LoginField
  error: FieldError
  onChangeField: (field: Partial<LoginField>) => void
  onSubmit: () => void
}

const LoginForm: React.SFC<Props> = props => {
  return (
    <div>
      <div onSubmit={props.onSubmit}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '400px',
          }}
        >
          <TextField
            required
            value={props.field.email}
            onChange={e => props.onChangeField({ email: e.target.value })}
            label="メールアドレス"
            type="text"
            autoComplete="email"
            margin="normal"
          />
          <TextField
            required
            value={props.field.password}
            onChange={e => props.onChangeField({ password: e.target.value })}
            label="パスワード"
            type="password"
            autoComplete="current-password"
            margin="normal"
          />
          <Button
            type="submit"
            onClick={props.onSubmit}
            variant="outlined"
            color="primary"
          >
            ログイン
          </Button>
        </div>
      </div>
    </div>
  )
}

export default LoginForm
