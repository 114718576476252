import * as React from 'react'
import { Device } from '../../types'
import { MenuItem } from '@material-ui/core'
import { Select } from '@material-ui/core'
import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { downloadCSV } from './operations'
import { Button } from '@material-ui/core'

type Props = {
  device: Device
}

function LogExportForm({ device }: Props) {
  const dispatch = useDispatch()

  const dates: { [ymd: string]: number } = {}
  _.forEach(device.logCounts, (v, ym) => {
    _.forEach(v, (n, d) => {
      dates[ym + '-' + d] = n
    })
  })
  const [date, setDate] = React.useState<string>('---')
  return (
    <div>
      <Select
        value={date}
        onChange={e => setDate(e.target.value)}
        inputProps={{ name: 'date' }}
      >
        <MenuItem value={'---'}>---</MenuItem>
        {_.map(dates, (n, date) => (
          <MenuItem key={date} value={date}>
            {date}({n})
          </MenuItem>
        ))}
      </Select>
      <Button
        variant="contained"
        disabled={date === '---'}
        onClick={() => {
          dispatch(downloadCSV(device.id, date))
        }}
      >
        Export
      </Button>
    </div>
  )
}
export default LogExportForm
