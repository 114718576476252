import { combineReducers } from 'redux'
import Auth from './store/Auth/reducer'
import DeviceById from './store/DeviceById/reducer'
import DeviceLogsByDid from './store/DeviceLogsByDid/reducer'
import LoginForm from './store/LoginForm/reducer'
import System from './store/System/reducer'
import { State } from './types'
import HistoryLogs from './store/HistoryLogs/reducer'

export default combineReducers<State>({
  Auth,
  DeviceById,
  DeviceLogsByDid,
  LoginForm,
  System,
  HistoryLogs,
})
