import * as React from 'react'
import { connect } from 'react-redux'

import { State as RootState } from '../../types'

import { Typography } from '@material-ui/core'
import { getVisibleDeviceIds } from '../../store/System/selectors'
import DeviceInfo from '../Device'

type Props = {
  deviceIds: string[]
}

const DeviceList: React.SFC<Props> = props => {
  const { deviceIds } = props
  return (
    <div>
      {deviceIds.length === 0 ? (
        <Typography>閲覧可能なデバイスがありません。</Typography>
      ) : (
        deviceIds.map(deviceId => (
          <DeviceInfo key={deviceId} deviceId={deviceId} />
        ))
      )}
    </div>
  )
}

const ms = (state: RootState) => ({
  deviceIds: getVisibleDeviceIds(state),
})

const conn = connect(
  ms,
  {},
)

export default conn(DeviceList)
