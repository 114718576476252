import { State, User } from '../../types'

export const getAuth = (state: State) => state.Auth
export const getLoading = (state: State) => state.Auth.loading
export const getUser = (state: State): User | undefined => {
  if (state.Auth.loading || !state.Auth.authorized) {
    return undefined
  }
  return state.Auth.user
}
export const getIsLogin = (state: State) =>
  !state.Auth.loading && state.Auth.authorized

export const getDeviceIds = (state: State): false | string[] => {
  const user = getUser(state)
  if (!user || !user.deviceIds) {
    return []
  }
  return Object.keys(user.deviceIds)
}
