import { State } from '../../types'

const getDevice = (state: State, deviceId: string) => {
  return state.DeviceLogsByDid[deviceId]
}

export const getDeviceLogs = (state: State, deviceId: string) => {
  const ref = getDevice(state, deviceId)
  if (!ref || !ref.logs) {
    return []
  }
  return ref.logs
}

export const getDeviceStateLogs = (state: State, deviceId: string) => {
  const ref = getDevice(state, deviceId)
  if (!ref || !ref.stateLogs) {
    return []
  }
  return ref.stateLogs
}
