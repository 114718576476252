// @flow
import { Typography } from '@material-ui/core'
import * as React from 'react'
import { ImgWrapper, Photo } from '..'
import { User } from '../../types'

type Props = {
  user: User | undefined
}

const UserForm: React.SFC<Props> = props => {
  const { user } = props
  if (!user) {
    return <div>loading</div>
  }
  return (
    <>
      <div>
        <ImgWrapper style={{ width: '100px' }}>
          <Photo data-timeout={true} src={user.photoURL} alt="" />
        </ImgWrapper>
        <div>
          <Typography>ユーザ名</Typography>
          <Typography variant="h4">{user.displayName}</Typography>
        </div>
      </div>
      <Typography>
        アイコンはGoogleアカウントのものになります。
        <a href="https://aboutme.google.com/">変更</a>
      </Typography>
    </>
  )
}
export default UserForm
